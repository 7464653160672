import * as React from 'react';
import Header from './../common/Header'

function Homepage() {
    return (
        <div>
            <Header></Header>
        </div>
    );
}

export default Homepage;
